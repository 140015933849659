<template>
  <div class="agreement">
    <div>
      <a-page-header title="借伞数据" class="hearStyle" />
      <a-form layout="inline">
        <a-form-item label="用户手机号：">
          <a-input-number v-model.trim="searchForm.mobile" placeholder="请输入用户手机号" :min="0" class="inputClass" />
        </a-form-item>
        <a-form-item label="状态：">
          <a-select style="width: 200px" placeholder="请选择" v-model="searchForm.status">
            <a-select-option v-for="item in projects" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="借伞时间：">
          <a-range-picker
              show-time
              v-model="searchForm.borrowTime"
          >
          </a-range-picker>
        </a-form-item>
        <span>
          <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
          <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
          <a-button style="margin-left: 15px" @click="exportFile">导出</a-button>
        </span>
      </a-form>
      <a-table :columns="columns" :pagination="pagination.total ? pagination : false" @change="pageChange" style="margin-top: 10px" :data-source="tableData" bordered>
        <template slot="operation" slot-scope="text, row">
          <div v-if="row.status == 3 && !row.operator" class="jfBack" @click="clcBackIntegral(row)">积分返还</div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { projectName } from '@/api/modularManagement';
import { borrowingUmbrella, recordExportCheck, recordExport, recordReturn } from '@/api/umbrellaData';
import { runDefaultMountEffects } from 'qiankun';

// 状态枚举
// 借伞状态
const statusEnum = {
  noBackNoOut: 0, // 未返还（未过期
  yesBackNoOut: 1, // 已返还（未过期）
  noBackYesOut: 2, // 未返还（已过期）
  yesBackYesOut: 3 // 已返还（已过期）
}

export default {
  data() {
    return {
      moment,
      tableData: [],
      projects: [
        { id: null, name: '全部' },
        { id: 0, name: '未返还（未过期）' },
        { id: 1, name: '已返还（未过期）' },
        { id: 2, name: '未返还（已过期）' },
        { id: 3, name: '已返还（已过期）' },
      ],
      searchForm: {
        mobile: '',
        status: null,
        borrowTime: [],
        borrowTimeStart: '',
        borrowTimeEnd: '',
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      page: 1,
      size: 10,
      columns: [
        { title: '用户id', dataIndex: 'userId', key: 'userId', align: 'center' },
        { title: '用户手机号', dataIndex: 'mobile', key: 'mobile', align: 'center' },
        { title: '借伞时间', dataIndex: 'borrowTime', key: 'borrowTime', align: 'center' },
        { title: '消耗积分', dataIndex: 'consumePoints', key: 'consumePoints', align: 'center' },
        { title: '还伞时间',
          dataIndex: 'returnTime',
          key: 'returnTime',
          align: 'center',
          customRender: (text, row, index) => {
            // 未返还（未过期）、未返还（已过期）为-
            if (row.status == statusEnum.noBackNoOut || row.status == statusEnum.noBackYesOut) {
              return '-';
            } else {
              return text;
            }
          },
        },
        { title: '返还积分', 
          dataIndex: 'actReturnPoints',
          key: 'actReturnPoints',
          align: 'center',
          customRender: (text, row) => {
            if (row.status == statusEnum.noBackNoOut || row.status == statusEnum.noBackYesOut) {
              return '-';
            } else {
              return text;
            }
          },
        },
        {
          title: '还伞返还积分有效期',
          dataIndex: 'returnValidityPeriodDisplay',
          key: 'returnValidityPeriodDisplay',
          align: 'center',
        },
        { title: '预计返还积分', dataIndex: 'returnPoints', key: 'returnPoints', align: 'center' },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: '140px',
          customRender: (text) => {
            if (text == statusEnum.noBackNoOut) {
              return '未返还（未过期）';
            } else if(text == statusEnum.yesBackNoOut) {
              return '已返还（未过期）';
            } else if(text == statusEnum.noBackYesOut) {
              return '未返还（已过期）';
            } else if(text == statusEnum.yesBackYesOut) {
              return '已返还（已过期）';
            }
          },
        },
        { title: '操作人', dataIndex: 'operator', align: 'center', width: '75px' },
        { title: '操作时间', dataIndex: 'operatorTime', align: 'center' },
        {
          width: '90px',
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center' 
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const params = {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      const borrowTime = this.searchForm.borrowTime
      if (borrowTime && borrowTime.length > 0) {
        params.borrowTimeStart = moment(this.searchForm.borrowTime[0]).format('YYYY-MM-DD HH:mm:ss')
        params.borrowTimeEnd = moment(this.searchForm.borrowTime[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        params.borrowTimeStart = ''
        params.borrowTimeEnd = ''
      }
      try {
        const res = await borrowingUmbrella(params)
        const {
          data: { items, total },
          code,
        } = res;
        if (code === '200') {
          this.tableData = items;
          this.pagination.total = total;
        }
      } catch (e) {
        this.$message.error(e.msg);
      }
    },
    // 重置
    async resetSearchForm() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm.mobile = '';
      this.searchForm.status = null;
      this.searchForm.borrowTime = [];
      await this.getList();
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getList();
    },
    search() {
      this.pagination.current = 1;
      this.getList();
    },
    // 导出
    exportFile() {
      const _this = this;
      this.$confirm({
        title: `确定要导出文件吗？(支持导出的最大条数为5000条)`,
        onOk() {
          _this.recordExportNew() // 借伞记录导出
        },
        onCancel() {},
        class: 'test',
      });
    },
    // 借伞记录导出
    async recordExportNew() {
      console.log(423423432)
      try {
        const obj = {
          ...this.searchForm,
          page: this.pagination.current,
          size: this.pagination.pageSize,
        }
        const borrowTime = this.searchForm.borrowTime
        if (borrowTime && borrowTime.length > 0) {
          obj.borrowTimeStart = moment(this.searchForm.borrowTime[0]).format('YYYY-MM-DD HH:mm:ss')
          obj.borrowTimeEnd = moment(this.searchForm.borrowTime[1]).format('YYYY-MM-DD HH:mm:ss')
        } else {
          obj.borrowTimeStart = ''
          obj.borrowTimeEnd = ''
        }
        let checkRes = await recordExportCheck(obj)
        if (checkRes.code) {
          this.$message.error(checkRes.msg);
        } else {
          let res = await recordExport(obj)
          const blob = new Blob([res.data]);
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.setAttribute("download", "借伞数据.xlsx");
          a.click();
          this.$message.success('操作成功');
        }
      } catch (e) {
        this.$message.error(e.msg);
      }
    },
    // 点击积分返还
    clcBackIntegral(row) {
      console.log(row);
      const _this = this;
      this.$confirm({
        title: `因用户还伞时间超出返还积分有效期，确认是否返还用户积分`,
        // onOk() {
        //   _this.$message.success('操作成功');
        //   _this.$message.error('操作失败，请稍后重试');
        // },
        async onOk() {
          _this.recordReturn(row.id) // 借伞记录积分返还
        },
        onCancel() {},
        class: 'test',
        okText: '是',
        cancelText: '否',
      });
    },
    // 借伞记录积分返还
    async recordReturn(recordId) {
      try {
        const obj = {
          recordId: recordId
        }
        let res = await recordReturn(obj)
        if (res.code === '200') {
          this.$message.success('操作成功');
          this.getList()
        }
      } catch (e) {
        this.$message.error("操作失败，请稍后重试");
      }
    }
  },
};
</script>
<style scoped lang="scss">
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
.jfBack {
  cursor: pointer;
  color: #1890ff;
}

.inputClass {
  width: 200px;
}
::v-deep .inputClass:hover .ant-input-number-handler-wrap {
  display: none !important;
}
</style>
