import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import http from "@/utils/axios";


const prefix = '/api/dscloud-member-mall'


// 借伞记录分页查询
export const borrowingUmbrella = (data) => {
  return fetchApi(prefix + '/points-borrow-umbrella/record/list', data, 'POST', 'data')
}

// 借伞记录导出校验
export const recordExportCheck = (data) => {
  return fetchApi(prefix + '/points-borrow-umbrella/record/export', data, 'POST', 'data')
}

// 借伞记录导出
export const recordExport = (data) => {
  // return fetchApi(prefix + '/points-borrow-umbrella/record/export', data, 'POST', 'data')
  return http({
    url: prefix + '/points-borrow-umbrella/record/export',
    responseType: 'blob',
    method: 'POST',
    data: data
  })
}

// 借伞记录积分返还
export const recordReturn = (data) => {
  return fetchApi(prefix + '/points-borrow-umbrella/record/points-return', data, 'POST', 'data')
}


